import React from 'react'
import { Heading, Text, TextContainer } from '../components/shared/Typography'

const FAQs = () => (
  <TextContainer
    style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <Heading>FAQs</Heading>
    <Text>
      <a href='/posts/is-uv-sterilization-effective-for-viruses-and-bacteria/'>
        IS UV STERILIZATION EFFECTIVE FOR VIRUSES AND BACTERIA?
      </a>
    </Text>
  </TextContainer>
)

export default FAQs
